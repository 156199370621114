import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Home from './views/Home';
import NotFound from './views/NotFound';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor} from './store';
import './style/App.scss';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
  <Header />
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  <Footer />
  </PersistGate>
</Provider>);
